.title {
  margin: 0 0 var(--space-m);
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: var(--space-b);
}

.button {
  min-width: 330px;
}

.collapse {
  margin-top: var(--space-l);
}

.collapse.isOpen {
  overflow: visible;
}

.container {
  composes: main from 'containers';
}

@media (--desktop-m) {
  .button {
    min-width: 240px;
  }
}

@media (--desktop) {
  .buttonWrapper {
    margin-top: var(--space-xxl);
  }

  .collapse {
    margin-top: var(--space-n);
  }
}

@media (--mobile-m) {
  .buttonWrapper {
    margin-top: var(--space-l);
  }

  .button {
    min-width: 185px;
  }
}

@media (--mobile) {
  .button {
    width: 100%;
  }
}
