.wrapper {
  position: relative;

  display: block;

  color: var(--color-black);
  text-decoration: none;
}

.imgWrapper {
  position: relative;

  aspect-ratio: '13.585:9';

  line-height: 0;

  background: var(--color-light-gray);
  border-radius: 5px;
}

.image {
  object-fit: contain;
}

.titleWrap {
  position: absolute;
  top: var(--space-n);
  left: var(--space-n);

  display: flex;
}

.title {
  font-weight: 500;
  font-size: var(--size-m);
  line-height: 1.5;

  @media (--desktop) {
    font-size: var(--size-m);
    line-height: 1.2;
  }
}

.count {
  margin-left: var(--space-xxs);

  color: var(--color-dark-gray);
  font-size: var(--size-xs);
  line-height: 1.5;
}

.discount {
  position: absolute;
  bottom: var(--space-n);
  left: var(--space-n);
  z-index: 1;

  box-sizing: border-box;
  min-width: 139px;
  margin-left: var(--space-m);

  padding: var(--space-xs) var(--space-n);
  padding-left: var(--space-n);

  font-weight: 500;
  font-size: var(--size-m);
  line-height: 1.2;

  background-color: var(--color-white);

  border-radius: 0 8px 8px 0;

  &::before {
    position: absolute;
    top: 0;
    left: var(--space-m-neg);

    width: 33px;
    height: 100%;

    background: url('./badge-corner.svg') no-repeat center;
    background-size: contain;

    content: '';
  }
}

.discount.big {
  bottom: var(--space-n);
  left: var(--space-n);

  &.longPrice {
    bottom: var(--space-s);
    left: var(--space-s);
  }

  @media (--desktop) {
    bottom: var(--space-s);
    left: var(--space-s);

    &.longPrice {
      bottom: var(--space-xs);
      left: var(--space-xs);
    }
  }
}

.discount.small {
  bottom: var(--space-s);
  left: var(--space-s);

  &.longPrice {
    bottom: var(--space-xs);
    left: var(--space-xs);
  }
}

.discount.longPrice {
  margin-left: var(--space-s);
  padding: var(--space-xxs) var(--space-s);

  font-size: var(--size-s);
  line-height: 1.5;
}
