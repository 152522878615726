.sound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  cursor: pointer;
}

.image {
  width: 34px;
  height: 24px;

  transition: transform 200ms;
}

@media (hover: hover) {
  .sound:hover .image {
    transform: scale(1.2);
  }
}
