.prev,
.next {
  position: absolute;
  top: 50%;
  z-index: 3;

  transform: translateY(-50%);
}

.prev {
  left: var(--space-xxl);
}

.next {
  right: var(--space-xxl);
}

@media (--desktop-l) {
  .prev {
    left: var(--space-xxb);
  }

  .next {
    right: var(--space-xxb);
  }
}
