.page {
  display: flex;
  flex-direction: column;
  gap: 100px;
  max-width: 1920px;
  min-height: 100vh;
  padding-bottom: 80px;
  margin-inline: auto;
}

.container {
  composes: main from 'containers';
}

@media (--desktop-m) {
  .page {
    gap: 80px;
    padding-bottom: 60px;
  }
}

@media (--desktop) {
  .page {
    gap: 60px;
  }
}
