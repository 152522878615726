.wrapper {
  position: relative;

  display: inline-block;
  width: max-content;
}

.icon {
  width: max(30px, 1.5625vw);
  height: max(30px, 1.5625vw);

  background-color: transparent;
}

.iconCopied {
  color: var(--color-green);
}

.text {
  margin: 0;

  font-weight: 500;
  font-size: max(35px, 1.82vw);
}

.content {
  display: flex;
  flex-wrap: nowrap;
  gap: max(10px, 0.52vw);
  align-items: center;
  padding: max(5px, 0.26vw) max(15px, 0.78125vw);

  white-space: nowrap;

  cursor: pointer;

  transition: background-color 100ms ease-out;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    border-radius: max(50px, 2.6vw);

    content: '';
  }
}

.dark {
  --active: 0.5;

  .content .text,
  .content .iconCopy {
    color: var(--color-white);
  }

  .content::before {
    background-color: var(--color-black);
    opacity: 0.3;
  }

  &:hover {
    .content::before {
      opacity: var(--active);
    }
  }
}

.light {
  --active: 1;

  .content .text,
  .content .iconCopy {
    color: var(--color-black);
  }

  .content::before {
    background-color: var(--color-white);
    opacity: 0.6;
  }

  &:hover {
    .content::before {
      opacity: var(--active);
    }
  }
}

.copied {
  .content::before {
    opacity: var(--active);
  }
}

@media (--desktop-m) {
  .text {
    font-size: 25px;
  }
}

@media (--mobile-m) {
  .content {
    gap: 5px;
  }

  .text {
    font-size: 18px;
  }

  .icon {
    width: 25px;
    height: 25px;
  }
}
