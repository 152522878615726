.container {
  position: relative;

  display: flex;
  gap: var(--space-xs);
  align-items: center;
  justify-content: center;
  padding: var(--space-xxs) var(--space-xs);

  color: var(--color-black);

  font-family: var(--font-main);
  line-height: 150%;

  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  cursor: pointer;
  &.hovered {
    &::after {
      position: absolute;
      right: calc(50% - var(--space-xxs));
      bottom: var(--space-s-neg);
      z-index: var(--z-index-popup);

      width: 10px;
      height: 10px;

      background-color: var(--color-white);
      transform: rotate(45deg);

      content: '';
    }
  }
}

.text {
  color: var(--color-grafit);
  font-size: var(--size-n);
}

.popup {
  right: 0;
  left: auto;

  width: max-content;
  padding: var(--space-xs) var(--space-s);

  transform: none;
}

@media (--desktop-m) {
  .text {
    font-size: var(--size-s);
  }
}
