.banner {
  --color: var(--color-black);
  position: relative;

  display: flex;
  width: 100%;
  height: 750px;
  overflow: hidden;

  color: var(--color);
}

.video,
.image {
  position: absolute;
  top: 0;
  right: 0;

  bottom: 0;
  left: 0;
}

.video {
  transform: translate3d(0px, 0px, -100px);
}

.image {
  z-index: 1;

  padding-bottom: 0;
}

.content {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  font-family: var(--font-main);
  line-height: 1.5;
  white-space: normal;
}

/* + Content x-y alignment */
.centerCenter {
  align-items: center;

  text-align: center;

  & > :first-child {
    margin-top: auto;
  }
}

.centerTop {
  align-items: center;

  text-align: center;

  & > :first-child {
    margin-top: 60px;
  }
}

.leftCenter {
  align-items: flex-start;
  padding-left: 221px;

  text-align: left;

  & > :first-child {
    margin-top: auto;
  }
}

.leftTop {
  align-items: flex-start;
  padding-left: 221px;

  text-align: left;

  & > :first-child {
    margin-top: 60px;
  }
}
/* - Content x-y alignment */

.period {
  margin-bottom: 35px;

  font-weight: 500;
  font-size: 22px;
}

.title {
  margin-bottom: 35px;

  font-weight: 500;
  font-size: 70px;
  font-family: var(--font-hero);
  line-height: 1.3;
}

.subtitle {
  margin-bottom: 25px;

  font-size: 35px;
}

.link {
  margin-bottom: 35px;

  font-weight: 500;

  font-size: 22px;

  border-bottom: 1px solid var(--color);

  transition: border-color 200ms;

  &.withSound {
    margin-bottom: 70px;
  }
}

.sound {
  margin-bottom: auto;

  transform: translateX(-8px);
  opacity: 0.7;
}

.timer {
  margin-bottom: 10px;
}

.timer:last-child {
  margin-bottom: 40px;
}

.condition {
  margin-bottom: 40px;

  font-size: 12px;

  opacity: 0.7;
}

.period,
.title,
.subtitle,
.link,
.condition {
  transition: 0.25s ease-in;
}

.inversed {
  --color: #fdfed0;
}

@media (--desktop-m) {
  .banner {
    height: 600px;
  }

  /* + Content x-y alignment */
  .centerTop {
    & > :first-child {
      margin-top: 40px;
    }
  }

  .leftCenter {
    padding-left: 150px;
  }

  .leftTop {
    padding-left: 150px;

    & > :first-child {
      margin-top: 40px;
    }
  }
  /* - Content x-y alignment */

  .period {
    margin-bottom: 25px;

    font-size: 18px;
  }

  .title {
    margin-bottom: 25px;

    font-size: 50px;
  }

  .subtitle {
    margin-bottom: 15px;

    font-size: 25px;
  }

  .link {
    margin-bottom: 25px;

    font-size: 18px;

    &.withSound {
      margin-bottom: 50px;
    }
  }

  .condition {
    margin-bottom: 30px;
  }
}

@media (max-width: 1535px) {
  .banner {
    height: 534px;
  }
}

@media (--desktop) {
  .banner {
    height: 480px;
  }

  /* + Content x-y alignment */
  .leftCenter,
  .leftTop {
    padding-left: 40px;
  }
  /* - Content x-y alignment */

  .period {
    margin-bottom: 15px;

    font-size: 16px;
  }

  .title {
    margin-bottom: 15px;

    font-size: 30px;
  }

  .subtitle {
    margin-bottom: 10px;

    font-size: 18px;
  }

  .link {
    font-size: 16px;

    &.withSound {
      margin-bottom: 40px;
    }
  }

  .timer {
    margin-bottom: 5px;
  }
}

@media (--mobile-m) {
  .banner {
    height: 380px;
  }

  /* + Content x-y alignment */
  .centerTop {
    & > :first-child {
      margin-top: 30px;
    }
  }

  .leftCenter {
    padding-left: 15px;
  }

  .leftTop {
    padding-left: 15px;

    & > :first-child {
      margin-top: 30px;
    }
  }
  /* - Content x-y alignment */

  .condition {
    margin-bottom: 20px;
  }

  .link {
    margin-bottom: 15px;
  }
}

@media (--mobile) {
  .banner {
    height: 580px;
  }

  /* + Content x-y alignment */
  .centerCenter,
  .centerTop,
  .leftCenter,
  .leftTop {
    align-items: flex-start;
    padding: 30px 15px 20px;

    text-align: left;

    & > :first-child {
      margin-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
  /* - Content x-y alignment */

  .sound {
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
}

@media (hover: hover) {
  .link:hover {
    border-color: transparent;
  }
}
