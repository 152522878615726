.section {
  --height-map: 630px;
}

.title {
  font-weight: 500;
  font-size: var(--size-b);
  font-family: var(--font-hero);
  line-height: 1.5;
}

.text {
  composes: averageText from 'containers';

  margin-top: var(--space-m);

  font-size: var(--size-m);
  line-height: 150%;
}

.mapWithPopup {
  height: var(--height-map);
  margin-top: var(--space-xl);
}

.mapPlaceholder {
  height: var(--height-map);
  margin-top: var(--space-xxl);

  background: var(--color-light-gray);
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-xxl);
    line-height: 1.5;
  }

  .text {
    max-width: 83%;
  }
}

@media (--desktop) {
  .section {
    --height-map: 463px;
  }

  .title {
    font-size: var(--size-xl);
    line-height: 150%;
  }

  .text {
    max-width: unset;

    font-size: var(--size-n);
    line-height: 1.5;
  }
}

@media (--mobile-m) {
  .title {
    font-size: var(--size-l);
    line-height: 1.5;
  }
}

@media (--mobile) {
  .section {
    --height-map: 650px;
  }
  .title {
    font-size: var(--size-xm);
    line-height: 1.5;
  }
  .mapWithPopup {
    margin-top: var(--space-l);
  }
}
