.wrapper {
  position: relative;

  display: block;

  color: var(--color-black);
  text-decoration: none;
}

.imgWrapper {
  position: relative;

  height: 0;
  margin: 0;
  padding-bottom: 66.25%;
  overflow: hidden;

  line-height: 0;

  background: var(--color-light-gray);
  border-radius: 6px;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 90px;
  left: 0;

  padding-bottom: 0;
}

.titleWrap {
  margin-top: var(--space-n);
}

.cnImage {
  object-fit: contain;
}

.title {
  display: block;
  overflow: hidden;

  font-weight: 500;
  font-size: var(--size-l);
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (--desktop) {
    font-size: var(--size-l);
  }

  @media (--mobile-m) {
    &:before {
      height: 25px;
    }
  }
}

.count {
  display: block;
  padding-top: var(--space-xxs);

  color: var(--color-dark-gray);

  font-size: var(--size-m);
  line-height: 1.5;

  @media (--desktop) {
    font-size: var(--size-m);
  }

  @media (--mobile-m) {
    padding-top: var(--space-xxs);
  }
}
