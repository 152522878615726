.switch {
  --color: var(--color-black);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 202px;
  height: 102px;
  margin-bottom: auto;
  padding: 0 8px;

  border: 5px solid var(--color);
  border-radius: 80px;
  cursor: pointer;

  transition: 0.2s ease-in-out;
}

.image {
  width: 40px;
}

.slider {
  position: relative;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 78px;

  background-color: var(--color);
  border: none;
  border-radius: 100%;
  cursor: pointer;

  transition: 0.25s ease-in-out;
}

.switch {
  &.dark {
    .slider {
      left: 100px;

      background-color: #fdfed0;
    }
  }
}

.dark {
  border: 5px solid #fdfed0;
}

@media (--desktop-m) {
  .switch {
    width: 141px;
    height: 70px;
    padding: 0 5px;

    border: 4px solid var(--color);

    &.dark {
      border: 4px solid #fdfed0;
    }
  }
  .slider {
    width: 54px;
    height: 54px;
  }
  .image {
    width: 27px;
  }
  .switch {
    &.dark {
      .slider {
        left: 69px;
      }
    }
  }
}

@media (--desktop) {
  .switch {
    width: 92px;
    height: 48px;

    border: 2.5px solid var(--color);

    &.dark {
      border: 2.5px solid #fdfed0;
    }
  }
  .slider {
    width: 36px;
    height: 36px;
  }
  .image {
    width: 18px;
  }
  .switch {
    &.dark {
      .slider {
        left: 42px;
      }
    }
  }
}
