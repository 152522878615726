.imageWrapper {
  position: relative;

  height: 0;
}

.image {
  position: absolute;

  width: 100%;
  height: 100%;
}
