.card {
  position: relative;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  width: 100%;
  height: 100%;
  aspect-ratio: '573:700';
}

.text {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: var(--space-m) var(--space-m) var(--space-xxl);

  color: var(--color-black);
}

.image {
  position: absolute;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.cardTop {
  display: flex;
}

.cardBottom {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}

.inversed {
  color: var(--color-white);
}

.title {
  font-weight: 500;
  font-size: var(--size-xxl);
  font-family: var(--font-hero);
  line-height: 1.3;
}

.subtitle {
  font-size: var(--size-l);
  line-height: 1.5;
}

.period {
  margin-right: auto;

  font-weight: 500;
  font-size: var(--size-l);
  line-height: 1.5;
}

.discount {
  margin-left: auto;

  color: var(--color-black);
  font-size: var(--size-l);
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-xl);
  }

  .subtitle {
    font-size: var(--size-m);
  }

  .period {
    font-size: var(--size-m);
  }

  .cardBottom {
    gap: var(--space-xs);
  }

  .text {
    padding: var(--space-m);
    padding-bottom: var(--space-l);
  }

  .discount {
    font-size: var(--size-m);
  }
}

@media (--desktop) {
  .title {
    font-size: var(--size-m);
  }

  .subtitle {
    font-size: var(--size-s);
  }

  .period {
    font-size: var(--size-s);
  }

  .cardBottom {
    gap: var(--space-xxs);
  }

  .text {
    padding: var(--space-s);
    padding-bottom: var(--space-n);
  }

  .discount {
    font-size: var(--size-s);
  }
}

@media (--mobile-xxs) {
  .text {
    padding: var(--space-xs);
    padding-bottom: var(--space-n);
  }
}
