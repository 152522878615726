.video {
  width: 100%;
  height: 100%;
}

.player {
  position: relative;
  z-index: 2;

  display: block;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;

  opacity: 1;
}

.poster {
  position: absolute;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;
  object-fit: cover;
}
