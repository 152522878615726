.tooltip {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  z-index: 2;

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: max-content;
  padding: 10px 15px;

  color: var(--color-grafit);
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;

  background-color: var(--color-white);
  border-radius: 5px;
  box-shadow: 0 0 20px 0 #00000033;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;

  pointer-events: none;
}

.visible {
  visibility: visible;
  opacity: 1;

  animation-name: hideTooltip;
  animation-fill-mode: forwards;
}

@keyframes hideTooltip {
  to {
    opacity: 0;
  }
}

@media (--mobile-m) {
  .tooltip {
    padding: 8px 12px;

    font-size: 12px;
  }
}
