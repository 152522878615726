.popup {
  position: fixed;
  right: var(--space-xxl);
  bottom: 120px;
  z-index: var(--z-index-popup);

  &.enter {
    &.typeSlideUp {
      bottom: -100%;

      transition: bottom 0.5s ease-in-out;
    }

    &.typeDelay {
      opacity: 0;

      transition: opacity 0.2s ease-in-out;
    }
  }

  &.enterActive,
  &.enterDone {
    &.typeSlideUp {
      bottom: 120px;
    }

    &.typeDelay {
      opacity: 1;
    }
  }

  &.exit {
    &.typeSlideUp {
      bottom: 120px;

      transition: bottom 0.5s ease-in-out;
    }

    &.typeDelay {
      opacity: 1;

      transition: opacity 0.2s ease-in-out;
    }
  }

  &.exitActive,
  &.exitDone {
    &.typeSlideUp {
      bottom: -100%;
    }

    &.typeDelay {
      opacity: 0;
    }
  }
}

@media (--mobile) {
  .popup {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.enter {
      &.typeSlideUp,
      &.typeDelay {
        bottom: 0;

        opacity: 0;

        transition: opacity 0.2s ease-in-out;
      }
    }

    &.enterActive {
      &.typeSlideUp {
        bottom: 0;

        opacity: 0;
      }

      &.typeDelay {
        opacity: 0;
      }
    }

    &.enterDone {
      &.typeSlideUp {
        bottom: 0;

        opacity: 1;

        transition: opacity 0.2s ease-in-out;
      }

      &.typeDelay {
        opacity: 1;

        transition: opacity 0.2s ease-in-out;
      }
    }

    &.exitActive {
      &.typeSlideUp {
        bottom: 0;

        opacity: 1;
      }

      &.typeDelay {
        opacity: 1;
      }
    }

    &.exitDone {
      &.typeSlideUp {
        bottom: 0;

        opacity: 0;
      }

      &.typeDelay {
        opacity: 0;
      }
    }
  }
}
