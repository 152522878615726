.card {
  position: relative;

  display: block;
  padding: var(--space-xs) var(--space-xs) var(--space-n);

  background-color: var(--color-light-gray);
}

.title {
  color: var(--color-black);
  font-weight: 500;
  font-size: var(--size-m);
  line-height: 1.5;
  text-align: center;
}

.image {
  max-width: 165px;
  margin: 0 auto var(--space-xs);
  padding-bottom: 100%;

  background-color: transparent;
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-n);
  }
}

@media (--mobile-m) {
  .card {
    padding: var(--space-xxs) var(--space-xxs) var(--space-xs);
  }

  .title {
    font-size: var(--size-s);
  }
}
