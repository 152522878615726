.banner {
  position: relative;

  height: unset;
}

.image {
  aspect-ratio: '13.59:9';

  border-radius: 4px;
}

.cnImage {
  border-radius: 4px;
}

.content {
  position: absolute;
  top: var(--space-l);
  left: var(--space-l);
  z-index: 1;
}

.title {
  margin-bottom: var(--space-s);

  font-weight: 500;
  font-size: var(--size-xl);
  font-family: var(--font-hero);
  line-height: 1.5;
}

.text {
  margin-bottom: var(--space-l);

  font-size: var(--size-m);
  line-height: 1.5;
  white-space: pre-wrap;
}

.link {
  font-size: var(--size-l);
}

@media (--desktop-m) {
  .title {
    margin-bottom: var(--space-xs);

    font-size: var(--size-xm);
  }
  .text {
    margin-bottom: var(--space-n);

    font-size: var(--size-n);
  }
  .link {
    font-size: var(--size-m);
  }
}

@media (--desktop) {
  .content {
    top: var(--space-n);
    left: var(--space-n);
  }

  .link {
    font-size: var(--size-n);
  }
}

@media (--mobile-m) {
  .title {
    font-size: var(--size-m);
  }

  .text {
    font-size: var(--size-s);
  }

  .link {
    font-size: var(--size-s);
  }
}

@media (--mobile) {
  .content {
    top: var(--space-s);
    left: var(--space-s);
  }

  .text {
    margin-bottom: var(--space-s);
  }
}
