.slide {
  display: flex;
  flex-direction: column;
  gap: var(--space-n);
  width: 277px;
  height: 540px;
}

.card {
  position: relative;

  box-sizing: border-box;

  width: 100%;
  height: calc(50% - var(--space-n) / 2);
  padding: var(--space-n);

  color: var(--color-black);
  text-decoration: none;

  background-color: var(--color-light-gray);
}

.oneCard {
  height: 100%;
}

.imgContainer {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.img {
  background-color: var(--color-white);
}

.textContainer {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.name {
  font-weight: 500;
  font-size: var(--size-m);
  line-height: 150%;
}

.sticker {
  font-size: var(--size-s);
}

@media (--desktop-m) {
  .slide {
    height: 500px;
  }
}

@media (--desktop) {
  .name {
    font-size: var(--size-n);
  }
}

@media (--mobile-m) {
  .slide {
    gap: var(--space-xxs);
    width: 160px;
    height: 305px;
  }

  .card {
    padding: var(--space-xs);
  }

  .card:not(.oneCard) {
    height: calc(50% - var(--space-xxs) / 2);
  }

  .name {
    max-width: 94px;

    font-size: var(--size-s);
  }

  .sticker {
    font-size: var(--size-xs);
  }
}
