.productsContainer {
  flex: 1;

  aspect-ratio: '1:1';
}

.productsList {
  display: grid;
  grid-auto-rows: 1fr;
  gap: var(--space-l);
  overflow: hidden;
}

@media (--desktop-m) {
  .productsList {
    gap: var(--space-m);
  }
}
