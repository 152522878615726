.tabs {
  margin-top: var(--space-m);
}

.title {
  display: inline;
  margin: 0;

  font-weight: 500;
  font-size: inherit;
  font-family: var(--font-hero);
  line-height: 1.5;
  white-space: pre-line;
}

.titleIcon {
  display: inline-block;
  width: 36px;
  height: 35px;
  margin-left: var(--space-n);

  vertical-align: middle;

  background-color: unset;
}

@media (--desktop-m) {
  .titleIcon {
    width: 32px;
    height: 32px;
  }
}

@media (--mobile-m) {
  .titleIcon {
    width: 25px;
    height: 25px;
    margin-left: var(--space-xs);
  }
}
