.container {
  width: 100%;
  overflow: hidden;
}

.wrapper {
  composes: main from 'containers';

  display: flex;
  gap: var(--space-l);
  max-height: 780px;
}

.opacityTransition {
  transition: opacity 0.35s ease-in-out;
  &.enter {
    opacity: 0;
  }

  &.enterActive {
    opacity: 1;
  }

  &.exit {
    opacity: 1;
  }

  &.exitActive {
    opacity: 0;
  }
}

.imgContainer {
  position: relative;

  flex-basis: 66%;
}

.imgDescription {
  position: absolute;
  bottom: var(--space-xxl);
  left: var(--space-xxl);

  color: var(--color-white);
}

.gallery {
  margin-top: var(--space-xs);
}

.descriptionTitle {
  margin-bottom: var(--space-xs);

  font-weight: 500;
  font-size: var(--size-xl);
  line-height: 150%;
}

.descriptionLink {
  font-size: var(--size-m);
  line-height: 150%;
}

.interiorImage {
  height: 100%;
  aspect-ratio: '13.54:9';
}

@media (--desktop-m) {
  .imgDescription {
    bottom: var(--space-l);
    left: var(--space-n);
  }

  .interiorImage {
    aspect-ratio: '13.7:9';
  }

  .descriptionTitle {
    font-size: var(--size-l);
  }

  .descriptionLink {
    font-size: var(--size-s);
  }
}

@media (--desktop) {
  .wrapper {
    flex-direction: column;
    gap: 0;
  }

  .imgContainer {
    margin-bottom: var(--space-xs);
  }

  .imgDescription {
    bottom: var(--space-l);
    left: var(--space-l);
  }

  .descriptionTitle {
    font-size: var(--size-m);
  }

  .interiorImage {
    width: 100%;
    aspect-ratio: '13.75:7';
  }
}

@media (--mobile-m) {
  .imgDescription {
    bottom: var(--space-n);
    left: var(--space-n);
  }

  .interiorImage {
    aspect-ratio: '14.5:7';
  }
}

@media (--mobile) {
  .interiorImage {
    aspect-ratio: '3.84:4';
  }
  .wrapper {
    padding: 0;
  }
}
