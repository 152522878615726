.wrapper {
  display: inline-block;
  width: max-content;
}

.container {
  position: relative;
}

.content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  color: black;

  white-space: nowrap;
}

.promocodeWrapper {
  display: flex;
  align-items: center;
}

.icon {
  background-color: transparent;
}

.primary {
  .content {
    gap: max(10px, 0.52vw);
    padding: 0 10px;

    background-color: white;
    border-radius: 15px;
    cursor: pointer;

    transition: background-color 100ms ease-out;
  }

  .promocodeWrapper {
    gap: max(10px, 0.52vw);

    font-weight: 500;
  }

  .icon {
    width: max(30px, 1.5625vw);
    height: max(30px, 1.5625vw);
  }

  &.copied .content {
    background-color: #ffffff26;
  }
}

.secondary {
  .content {
    gap: max(10px, 0.52vw);

    font-weight: 500;
  }

  .promocodeWrapper {
    gap: max(5px, 0.26vw);
    padding: max(5px, 0.26vw) max(10px, 0.52vw);

    background-color: var(--color-light-gray);
    border-radius: max(50px, 2.6vw);
    cursor: pointer;

    transition: background-color 100ms ease-out;
  }

  .icon {
    width: max(30px, 1.5625vw);
    height: max(30px, 1.5625vw);
  }

  &.copied {
    .icon {
      color: var(--color-green);
    }
  }
}

@media (hover: hover) {
  .primary:hover {
    .content {
      background-color: #ffffff26;
    }
  }

  .secondary {
    .promocodeWrapper:hover {
      background-color: var(--color-medium-gray);
    }
  }
}

@media (--desktop-m) {
  .secondary {
    .icon {
      width: 25px;
      height: 25px;
    }
  }
}

@media (--desktop) {
  .primary {
    .content {
      border-radius: 7px;
    }
  }
}

@media (--mobile-m) {
  .primary {
    .content {
      gap: 5px;

      font-size: 18px;
    }

    .promocodeWrapper {
      gap: 5px;
    }

    .icon {
      width: 25px;
      height: 25px;
    }
  }

  .secondary {
    .content {
      font-size: 16px;
    }
  }
}
