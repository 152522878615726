.container {
  composes: main from 'containers';

  padding: var(--space-xb) var(--space-xxb);
  overflow: hidden;

  background-color: #d8e4e2;
}

.header {
  margin-bottom: var(--space-xb);
}

.slide {
  margin-right: var(--space-n);

  &:last-child {
    margin-right: 0;
  }
}

@media (--desktop-m) {
  .container {
    padding: var(--space-b) var(--space-xxl);
  }

  .header {
    margin-bottom: var(--space-b);
  }
}

@media (--mobile-m) {
  .container {
    padding: var(--space-l) var(--space-s);
  }

  .header {
    margin-bottom: var(--space-xxl);
  }

  .slide {
    margin-right: var(--space-xxs);
  }
}
