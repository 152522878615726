.gallery {
  --columns-count: 8;
  --space-between: 30px;
  composes: main from 'containers';

  width: 100%;
  overflow: hidden;
}

.slide {
  width: calc(
    100% / var(--columns-count) - var(--space-between) / var(--columns-count) *
      (var(--columns-count) - 1)
  );
  margin-right: var(--space-between);

  &:last-child {
    margin-right: 0;
  }
}

@media (--desktop-m) {
  .gallery {
    --columns-count: 6;
  }
}

@media (--desktop) {
  .gallery {
    --space-between: 20px;
  }

  .slide {
    width: 190px;
  }
}

@media (--mobile-m) {
  .slide {
    width: 145px;
  }
}
