.imageItem {
  position: relative;

  width: 39.5%;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-l);
}

.interiorImage {
  height: unset;
  aspect-ratio: '13.59:9';
}

.imageItem:nth-child(6n - 5),
.imageItem:nth-child(6n) {
  width: 17.3%;
  .interiorImage {
    aspect-ratio: '5.97:9';
  }
}

@media (--desktop-m) {
  .imageItem:nth-child(6n - 5),
  .imageItem:nth-child(6n) {
    width: 17.1%;
  }

  .imageItem:nth-child(6n - 4),
  .imageItem:nth-child(6n - 3),
  .imageItem:nth-child(6n - 2),
  .imageItem:nth-child(6n - 1) {
    width: 39%;
  }
}

@media (--desktop) {
  .grid {
    gap: var(--space-n);
  }

  .imageItem:nth-child(6n - 5),
  .imageItem:nth-child(6n) {
    width: 17.2%;
  }
}

@media (--mobile-m) {
  .imageItem:nth-child(6n - 5),
  .imageItem:nth-child(6n) {
    width: 29.8%;
  }

  .imageItem:nth-child(6n - 4),
  .imageItem:nth-child(6n - 1) {
    width: 67.4%;
  }

  .imageItem:nth-child(6n - 3),
  .imageItem:nth-child(6n - 2) {
    width: 48.6%;
  }
}

@media (--mobile) {
  .grid .imageItem {
    width: 100%;
  }

  .collapsed {
    .imageItem {
      .interiorImage {
        aspect-ratio: '13.59:9';
      }

      &:nth-child(9n - 6),
      &:nth-child(9n - 5),
      &:nth-child(9n) {
        .interiorImage {
          aspect-ratio: '5.97:9';
        }
      }
    }
  }
}
