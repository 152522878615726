.card {
  position: relative;

  display: flex;
  gap: var(--space-l);
  align-items: center;
  &.promo {
    .actualPrice {
      color: var(--color-red);
    }
  }
}

.img {
  position: relative;

  flex: 0 0 auto;

  box-sizing: border-box;
  width: 47%;
  max-width: 240px;
  height: 100%;
  overflow: hidden;

  background-color: var(--color-light-gray);
  border-radius: 5px;
}

.image {
  aspect-ratio: '1:1';
}

.cnImage {
  object-fit: contain;
}

.actions {
  position: absolute;
  top: var(--space-s);
  right: var(--space-s);
  z-index: 2;
}

.name {
  display: inline-block;
  max-width: 100%;

  overflow: hidden;

  color: var(--color-black);
  text-overflow: ellipsis;
}

.price {
  display: flex;
  align-items: center;
}

.actualPrice {
  font-weight: 500;
  font-size: var(--size-m);
  line-height: 1.5;
}

.expiredPrice,
.discount {
  margin-left: var(--space-xs);
}

.discount {
  font-size: 13px;
}

@media (--desktop-m) {
  .card {
    gap: var(--space-s);
  }

  .img {
    width: 41.5%;
  }

  .uzbDiscount {
    display: none;
  }
}

@media (--mobile) {
  .actions {
    display: none;
  }
}
