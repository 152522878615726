.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textContainer {
  display: flex;
  flex-direction: column;

  gap: var(--space-s);
}

.title {
  display: inline-flex;
  gap: var(--space-s);
  align-items: center;
  margin: 0;

  font-weight: 500;
  font-size: var(--size-xxl);
  font-family: var(--font-hero);
  line-height: 1.5;
}

.text {
  margin: 0;

  font-size: var(--size-l);
  line-height: 1.5;
}

.button {
  width: 330px;
}

.firstPart {
  display: inline-flex;
  gap: var(--space-s);
  align-items: center;

  &::after {
    display: inline-block;

    width: 40px;
    height: 40px;

    background-image: url('./car.svg');

    content: '';
  }
}

@media (--desktop-m) {
  .textContainer {
    gap: var(--space-xs);
  }

  .title {
    font-size: var(--size-xl);
  }

  .text {
    font-size: var(--size-m);
  }
}

@media (--desktop) {
  .title {
    font-size: var(--size-l);
  }
  .text {
    font-size: var(--size-n);
  }
}

@media (--mobile-m) {
  .header {
    flex-direction: column;
    gap: var(--space-n);
    align-items: flex-start;
    justify-content: center;
  }

  .firstPart {
    gap: var(--space-xs);
  }

  .title {
    flex-wrap: wrap;
    gap: 0 var(--space-xs);
  }
}

@media (--mobile-xxs) {
  .button {
    width: 290px;
  }
}
