.wrapper {
  width: 100%;
  overflow: hidden;
}

.gallery {
  position: relative;

  width: 100%;
}

.arrows {
  max-height: 450px;
}

.infoPopup {
  position: absolute;
  top: var(--space-n);
  right: var(--space-s);
  z-index: var(--z-index-popup);

  @media (--mobile-m) {
    top: var(--space-xs);
    right: var(--space-xs);
  }
}

.placeholder {
  width: 100%;
  height: 790px;

  background-color: var(--color-light-gray);

  @media (--desktop-m) {
    height: 640px;
  }

  @media (max-width: 1535px) {
    height: 570px;
  }

  @media (--desktop) {
    height: 506px;
  }

  @media (--mobile-m) {
    height: 406px;
  }

  @media (--mobile) {
    height: 606px;
  }
}
