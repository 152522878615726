.gridDesktop {
  display: inline-grid;
  grid-gap: var(--space-l);
  grid-template-areas: 'big big2';
  grid-template-columns: 1fr 1fr;
  grid-template-columns: auto;
  box-sizing: border-box;
  width: 100%;

  @media (--desktop) {
    grid-gap: var(--space-n);
  }

  & > div {
    min-width: 0;
  }
}

.gridDesktopTrends {
  grid-template-columns: 1fr 1fr;
}

.gridDesktopM {
  display: inline-grid;
  grid-gap: var(--space-n);
  grid-template-areas:
    'big big  small3 small4'
    'big big big2 big2'
    'small1 small2 big2 big2';
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: auto;
  box-sizing: border-box;
  width: 100%;

  @media (--only-desktop) {
    grid-gap: var(--space-l);
  }
}

.gridDesktopMTrends {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.cardDesktopM {
  grid-area: big;
  min-width: 0;
}

.cardDesktopM:nth-child(2) {
  grid-area: small1;
}

.cardDesktopM:nth-child(3) {
  grid-area: small2;
}

.cardDesktopM:nth-child(4) {
  grid-area: small3;
}

.cardDesktopM:nth-child(5) {
  grid-area: small4;
}

.cardDesktopM:nth-child(6) {
  grid-area: big2;
}
